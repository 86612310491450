import { $win, $body } from '../utils/globals';
import AOS from 'aos';

$win.on('load', function () {
	setTimeout(function() {
		$body.addClass('is-loaded');
	}, 100);
})

$win.on('load', function() {
	AOS.init({
		duration: 600,
		delay: 130,
		once: true,
	});
});



