import imagesLoaded from 'imagesloaded';
import Masonry from 'masonry-layout';

const jQueryBridget = require('jquery-bridget');
const Isotope = require('isotope-layout');
require('isotope-packery');


jQueryBridget( 'isotope', Isotope, $ );

const $tiles = $('.tiles').isotope({
	itemSelector: '.tile',
	// percentPosition: true,
// layoutMode: 'packery',
});

$tiles.imagesLoaded().progress( function() {
	$tiles.isotope('layout');
});

$('.tile > a').each( function () {
	if ($(this).next().length) {
		$(this).on('click', function (e) {
			e.preventDefault();
			$(this).parent().siblings().addClass('is-hidden');
			$(this).next().addClass('is-active');
		});

		$('.tiles .tile__back-to').on('click', function (e) {
			e.preventDefault();
			$('.tile').removeClass('is-hidden');
			$(this).parent().removeClass('is-active');
		});
	}
});
