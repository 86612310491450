import { $win } from "../utils/globals";

const barHeight = $(".bar").outerHeight();
const headerHeight = $(".header").outerHeight();

$win.on("scroll", function () {
	const winScroll = $win.scrollTop();

    let paddingTopValue;

    if (window.innerWidth <= 768) {
        paddingTopValue = "180px";
    } else if (window.innerWidth <= 1024) { 
        paddingTopValue = "250px";
    } else {  
        paddingTopValue = "320px";
    }

	if (winScroll >= 10) {
		$(".header").css("top", -barHeight);
		$(".header").addClass("has-bg");
		$(".gallery .main").css("padding-top", paddingTopValue);
	} else {
		$(".header").css("top", 0);
		$(".header").removeClass("has-bg");
		$(".gallery .main").css("padding-top", 0);
	}
});

$(window).on("load", function () {
	const winScroll = $win.scrollTop();
	if (winScroll >= 10) {
		$(".header").css("top", -barHeight);
		$(".header").addClass("has-bg");
	}

	let scrollTop = document.documentElement.scrollTop;

	console.log("scrollTop", scrollTop);

	// Get the offset (left, top) of #abs element after page load
	let { left, top } = $(".header").offset();

	console.log(".header top", top);

	if (scrollTop === 0) {
		// We are at the top
		$(".header").css("top", 0);
		$(".header").removeClass("has-bg");
	} else {
		// The page is scrolled down by scrollTop pixels
		$(".header").css("top", -barHeight);
		$(".header").addClass("has-bg");
		// Use scrollTop and left to calc new scroll value or set it to 0
		// You can use this to scroll the page at the top after each load
		// setTimeout(() => {
		//   window.scrollTo(0, 0);
		// }, 50);
	}
});
